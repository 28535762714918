
.Info {
  // width: 68.7%;
  width: 756px;
  background: #ffffff;
  display: flex;
  // margin-top: 40px;
  // margin-bottom: 80px;
  // box-shadow: 0px 0px 9px 3px rgba(204, 221, 244, 0.5);
  // border-radius: 20px;
}
.courseInfo {
  width: 100%;
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  // padding: 10px;
  display: flex;
  // justify-content: space-between;
  box-sizing: border-box;
  .col{
    width: 50%;
  }
  .infoItem {
    // width: 20%;
    // background: red;
    width: 100%;
    font-size: 14px;
    padding: 20px 20px 20px 0px;
    box-sizing: border-box;
    display: flex;
    > span {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #404040;
      white-space: nowrap;
      // min-width: 100px;
      display: block;
    }
    > span:nth-child(1){
      font-size: 12px;
      color: #8896A7;
      margin-left: 0px;
    }
    .line {
      width: 12px;
      height: 12px;
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #8896A7;
    }
  }
  .infoItem span:nth-child(odd) {
    margin-right: 10px;
  }
}
.pl40{
  padding-left: 0px;
}
.flex-end{
  justify-content: flex-end;
}
.flex-center{
  justify-content: center;
}
.courseInfo :nth-child(3) {
  text-align: right;
}

@media screen and (max-width: 768px) {
}


.CourseDetail {
  width: 100%;
  min-height: 768px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

.apply {
  margin: 0 auto;
  width: 756px;
  // height: 100%;
  height: 160px;
  /* height: 200px; */
  // text-align: center;
  margin-bottom: 0px;
  font-size: 42px;
  font-family: Regular;
  font-weight: 400;
  // background: #1446c1;
  position: relative;
  .bgColor {
    position: absolute;
    width: 36%;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1;
    // background: #1a1491;
    // background-color: #231f6a;
    // background-image: linear-gradient(90deg, #231f6a 0%, #1a1491 100%);
  }
  .bgColorjb {
    width: 10%;
    top: 0;
    right: 25%;
    height: 100%;
    z-index: 1;
    background: #1a1491;
  }
  .title {
    // font-size: 42px;
    // font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    // font-weight: 400;
    // color: #ffffff;
    // letter-spacing: 3px;
    // z-index: 3;
    // position: absolute;
    // top: 40px;
    // left: 50%;
    // transform: translateX(-50%);
    position: absolute;
    left: 0px;
    top: 25px;
    font-size: 24px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #101010;
    line-height: 36px;
  }
  .describe {
    position: absolute;
    left: 0px;
    top: 66px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #404040;
    line-height: 21px;
  }
  // .subTitle {
  //   position: absolute;
  //   top: 108px;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   font-size: 24px;
  //   font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  //   font-weight: 400;
  //   color: #ffffff;
  //   z-index: 3;
  // }
  .submit {
    // width: 110px;
    // height: 34px;
    position: absolute;
    top: 100px;
    left: 70px;
    transform: translateX(-50%);
    padding: 10px 32px;
    // background: #fe8d0a;
    background: #2885ff;
    border-radius: 6px;
    font-size: 14px;
    font-family: Medium;
    font-weight: 500;
    color: #ffffff;
    border: none;
    text-align: center;
    .content {
      text-align: center;
      vertical-align: middle;
    }
    img {
      padding-right: 8px;
      
    }
  }
  .submit1{
    // width: 110px;
    // height: 34px;
    position: absolute;
    top: 100px;
    left: 70px;
    transform: translateX(-50%);
    padding: 11px 50px;
    // background: #fe8d0a;
    background: #F6F6F6;
    border-radius: 6px;
    border: none;
    text-align: center;
    .content {
      text-align: center;
      vertical-align: middle;
      span{
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #999999;

      }
    }
  }
  .course-img{
    position: absolute;
    top: 24px;
    right: 30px;
    width: 184px;
    height: 110px;
    border-radius: 6px;
  }
}
.lineBox {
  margin: 0 auto;
  width: 756px;
  height: 1px;
  background: #F1F1F1;
}
.info {
  margin: 0 auto;
  width: 756px;
  height: 96px;
}
.courseInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // background: red;
  .el-menu {
    width: 756px;
    // margin-left: 562px;
    height: 62px;
    background: #ffffff;
    border-bottom: none;
    padding-left: 0px;
  }
  .el-menu--horizontal > .el-menu-item {
    width: 100px;
    height: 14px;
    font-size: 14px;
    font-family: Medium;
    font-weight: 500;
    color: #8896a7;
    border: none !important;
    height: unset;
    padding-left: 0px;
    &:hover {
      background: #ffffff;
    }
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    border: none;
    font-size: 14px;
    font-family: Regular;
    font-weight: 400;
    color: #101010 !important;
    background: #ffffff !important;
    position: relative;
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      bottom: 10px;
      // bottom: -10px;
      width: 56px;
      height: 2px;
      background: #2885ff;
      border-radius: 5px;
      left: 28%;
      transform: translateX(-50%);
    }
  }
}
